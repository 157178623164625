import Vue from "vue";
import Vuex from "vuex";
import Web3 from 'web3'
import WalletConnectProvider from '@walletconnect/web3-provider'

const Web3Modal = window.Web3Modal.default
const Fortmatic = window.Fortmatic
// Web3modal instance
var web3Modal
var networkId
// Chosen wallet provider given by the dialog window
let provider
// Address of the selected account
//let selectedAccount;
provider = new WalletConnectProvider({
  rpc: {
    1: 'https://mainnet.infura.io/v3/0ad3bc79368a458fa8ad8415ced969d6',
  },
  qrcodeModalOptions: {
    mobileLinks: ['metamask', 'trust'],
  },
})
var accounts
provider.on('connect', async () => {
  accounts = await web3.eth.getAccounts()
  alert(accounts)
})
var web3 = new Web3(provider)


Vue.use(Vuex)
export default new Vuex.Store({
    state: {
        account: null,
        error: null,
    },
    mutations: {
        SET_ERROR(state, error) {
            state.error = error
        },
        CLEAN_ERROR(state) {
            state.error = null
        },
        SET_ACCOUNT(state, account) {
            state.account = account
        },
        SET_WALLET_CONNECTION_ERROR(state, error) {
            state.walletConnetionError = error
        },
    },
    actions: {
        async connect({ commit }) {

      console.log('Initializing example')
      console.log('WalletConnectProvider is', WalletConnectProvider)
      console.log('Fortmatic is', Fortmatic)
      console.log('window.web3 is', window.web3)
      // Check that the web page is run in a secure context,
      // as otherwise MetaMask won't be available
      if (location.protocol !== 'https:') {
        // https://ethereum.stackexchange.com/a/62217/620
        //const alert = document.querySelector("#alert-error-https");
        //alert.style.display = "block";
        //document.querySelector("#btn-connect").setAttribute("disabled", "disabled")
        return
      }
      // Tell Web3modal what providers we have available.
      // Built-in web browser provider (only one can exist as a time)
      // like MetaMask, Brave or Opera is added automatically by Web3modal
      const providerOptions = {
        walletconnect: {
          package: WalletConnectProvider,
          options: {
            rpc: {
              1: 'https://mainnet.infura.io/v3/0ad3bc79368a458fa8ad8415ced969d6',
            },
            network: 'mainnet',
            chainId: 0x04, //0x01
          },
        },
      }
      web3Modal = new Web3Modal({
        rpc: {
          1: 'https://mainnet.infura.io/v3/0ad3bc79368a458fa8ad8415ced969d6',
        },
        cacheProvider: true, // optional
        providerOptions, // required
        disableInjectedProvider: false, // optional. For MetaMask / Brave / Opera.
      })
      console.log('Web3Modal instance is', web3Modal)

            
            try {

                if (window.ethereum) {
                    window.web3 = new Web3(window.ethereum);
                } else if (window.web3) {
                    window.web3 = new Web3(window.web3.currentProvider);
                } else {
                    window.alert(
                        "Non-Ethereum browser detected. You should consider trying MetaMask !"
                    );
                }
                console.log('Opening a dialog', web3Modal)
                try {
                  provider = await web3Modal.connect()
                } catch (e) {
                  console.log('Could not get a wallet connection', e)
                  return
                }
                

                const accounts = await web3.eth.requestAccounts()
                   

                if (accounts && accounts.length) {
                    commit('SET_ACCOUNT', accounts[0])
                    commit('CLEAN_ERROR')
                    window.ethereum.on("accountsChanged", async (accounts) => {
                        commit('SET_ACCOUNT', accounts[0])
                      });
                      window.ethereum.on("connect", async (accounts) => {
                        commit('SET_ACCOUNT', accounts[0])
                      });
                    this.$router.push('whitelist')
                }
            } catch (ex) {
                commit('SET_WALLET_CONNECTION_ERROR', ex.message)
                commit('SET_ERROR', ex)
            }

        },
    },
    getters: {
        account: state => state.account,
        error: state => state.error,
    },
    modules: {}
})