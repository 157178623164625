export default [
  // {
  //   path: "/connect",
  //   meta: {},
  //   name: "connect",
  //   component: () => import("@/pages/connect/index"),
  // },
  // {
  //   path: "/mint",
  //   meta: {},
  //   name: "mint",
  //   component: () => import("@/pages/mint/index"),
  // },
  // {
  //   path: "/private",
  //   meta: {},
  //   name: "private",
  //   component: () => import("@/pages/private/index"),
  // },
   {
     path: "/whitelist",
     meta: {},
     name: "whitelist",
     component: () => import("@/pages/whitelist/index"),
   },
  {
    path: "/public",
    meta: {},
    name: "public",
    component: () => import("@/pages/public/index"),
  },
  {
    path: "*",
    meta: {},
    name: "connect",
    component: () => import("@/pages/private/index"),
  },
];
